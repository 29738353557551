import React from "react";
import { useHistory, useParams } from "react-router-dom";
import useHasAccess from "../hooks/useAccessToken";
import useLocalStorage from "../hooks/useLocalStorage";
import NotFound from "./NotFound";

const Token = () => {
  // eslint-disable-next-line no-unused-vars
  const [_, setAccessToken] = useLocalStorage("accessToken", null);
  const hasAccess = useHasAccess();
  const { id } = useParams();
  const history = useHistory();
  // If correct token set localStorage accessToken
  React.useEffect(() => {
    if (process.env.REACT_APP_ACCESS_TOKEN === id) {
      setAccessToken(id);
      history.replace("/");
    }
  }, [id, setAccessToken, history]);

  React.useEffect(() => {
    if (hasAccess) {
      history.replace("/");
    }
  }, [hasAccess, history]);

  return <NotFound />;
};
export default Token;
