import React from "react";
import { Box, Text } from "../components";

export default function NotFound() {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 36,
      }}
    >
      <Text>404: Not Found</Text>
    </Box>
  );
}
