import { Command } from "react-feather";
import { COLORS } from "./Theme";
const Loading = () => {
  return (
    <div className="Loading">
      <Command size={24} color={COLORS.yellow100} />
    </div>
  );
};
export default Loading;
