import Loading from "./Loading";
import Box from "./Box";

const LoadingOverlay = () => {
  return (
    <Box
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "#ffffff80",
      }}
    >
      <Loading />
    </Box>
  );
};

export default LoadingOverlay;
