import React from "react";
import Text from "./Text";
import { COLORS } from "./Theme";

const Button = ({ title, onClick, children, disabled, type }) => {
  return (
    <button
      onClick={disabled ? () => null : onClick}
      style={{
        padding: 10,
        backgroundColor: disabled ? COLORS.gray25 : COLORS.cobalt,
        alignItems: "center",
        borderRadius: 10,
        cursor: "pointer",
      }}
      type={type}
    >
      {title && <Text color={disabled ? "gray50" : "white"}>{title}</Text>}
      {children}
    </button>
  );
};

export default Button;
