import React from "react";
import { useAuth } from "../context";
import { Box, Text, TextInput, Button } from "../components";

export default function SignInForm() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(null);
  const { signIn } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);

    if (!!email && !!password) {
      signIn({ email, password }).catch((error) => setError(error.message));
    } else {
      setError("Email and password are required.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && (
        <>
          <Text color="red100">{error}</Text>
          <Box style={{ height: 20 }} />
        </>
      )}
      <Box>
        <TextInput
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Box style={{ height: 20 }} />
        <TextInput
          name="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Box style={{ height: 10 }} />
        <Button onClick={(e) => handleSubmit(e)} title="Sign In" />
      </Box>
    </form>
  );
}
