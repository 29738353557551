import React, { useEffect } from "react";
import { firebase } from "../../lib/firebase";
import { AuthReducer } from "./AuthReducer";

// SETUP
export const initialState = {
  currentUser: null,
  isAuthenticated: false,
  signUp: () => null,
  signIn: () => null,
  signOut: () => null,
};
export const AuthContext = React.createContext(initialState);
export const useAuth = () => React.useContext(AuthContext);

// PROVIDER
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(AuthReducer, initialState);
  const [loading, setLoading] = React.useState(true);
  const auth = firebase.auth();

  // listen for auth state change
  useEffect(() => {
    setLoading(true);
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setAuthUser(authUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, [auth]);

  // ACTIONS
  const setAuthUser = (authUser) => {
    dispatch({
      type: "SET_AUTH_USER",
      payload: { user: authUser },
    });
  };

  const signUp = async ({ email, password }) => {
    setLoading(true);
    return await auth
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        dispatch({
          type: "SIGN_IN",
          payload: { user },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const signIn = async ({ email, password }) => {
    setLoading(true);
    return await auth
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        dispatch({
          type: "SIGN_IN",
          payload: { user },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const signOut = async () => {
    await auth.signOut();
    dispatch({
      type: "SIGN_OUT",
    });
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        currentUser: state.currentUser,
        isAuthenticated: state.isAuthenticated,
        signUp,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
