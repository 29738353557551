import React from "react";
import { TYPOGRAPHY, COLORS } from "./Theme";

const Text = ({ as = "p", children, kind, color, style }) => {
  const colorStyle = {
    color: color ? COLORS[color] : COLORS.gray100,
  };
  const textStyle = kind ? TYPOGRAPHY[kind] : TYPOGRAPHY.base;
  return React.createElement(
    as,
    { style: { ...textStyle, ...colorStyle, ...style } },
    children
  );
};

export default Text;
