import React from "react";
import Box from "./Box";
import Text from "./Text";

const TextInput = ({
  name,
  disabled,
  type = "text",
  label,
  error,
  onChange,
  ...rest
}) => {
  return (
    <>
      {label && (
        <Box style={{ paddingBottom: 4, paddingLeft: 10 }}>
          <Text kind="small">{label}</Text>
        </Box>
      )}
      <input
        name={name}
        type={type}
        style={{
          padding: 10,
          borderColor: "#E5E5E5",
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 10,
          fontSize: 18,
        }}
        disabled={!!disabled}
        {...rest}
        onChange={onChange}
      />
      {error && (
        <Box style={{ paddingTop: 6, paddingLeft: 10 }}>
          <Text kind="small" color="red100">
            {error}
          </Text>
        </Box>
      )}
    </>
  );
};
export default TextInput;
