import React from "react";
import { db } from "../lib/firebase";
import { CSSTransition } from "react-transition-group";
import FlowerTop from "../assets/flower-top.png";
import FlowerBottom from "../assets/flower-bottom.png";
import JoannWeddingAnncouncement from "../assets/joann-wedding-announcement.jpg";
import JAKSlideshowThumb from "../assets/jak_slideshow_thumb.jpg";
import useHasAccess from "../hooks/useAccessToken";
import { useHistory } from "react-router";
import useOnScreen from "../hooks/useOnScreen";
import { Slideshow } from "../components";

function Flowers({ open }) {
  return (
    <>
      <CSSTransition in={open} timeout={3000} classNames="top-flower">
        <div className="top-flower">
          <img src={FlowerTop} alt="Cobalt Flowers" />
        </div>
      </CSSTransition>
      <CSSTransition in={open} timeout={3000} classNames="bottom-flower">
        <div className="bottom-flower">
          <img src={FlowerBottom} alt="Cobalt Flowers" />
        </div>
      </CSSTransition>
    </>
  );
}

function Splash() {
  return (
    <div className="Splash">
      <div className="Splash-Text">
        <p
          style={{
            fontSize: "12px",
            fontWeight: "600",
            letterSpacing: "0.2rem",
            paddingBottom: "0.5rem",
          }}
        >
          IN LOVING MEMORY
        </p>
        <h1 className="serif" style={{ lineHeight: 1 }}>
          JoAnn Morrison Kremer
        </h1>
      </div>
      <div className="Splash-Photo-Wrapper">
        <div className="Splash-Photo-Border">
          <img
            src={JoannWeddingAnncouncement}
            alt="Joann Morrison Kremer"
            style={{ marginRight: "-20px", marginTop: "-20px" }}
          />
        </div>
      </div>
    </div>
  );
}

function Bio({ setSlideshowOpen }) {
  return (
    <div className="Bio">
      <div className="Bio-Pictures">
        <div className="Bio-Col-Inner">
          <h2>Slideshow</h2>
          <button
            className="OpenSlideshowButton"
            onClick={() => setSlideshowOpen(true)}
          >
            <img src={JAKSlideshowThumb} alt="View slideshow" />
          </button>
        </div>
      </div>
      <div className="Bio-Text">
        <div className="Bio-Col-Inner">
          <h2>Obituary</h2>
          <p>
            JoAnn Morrison Kremer, beloved Mother, Grandmother, Great
            Grandmother, and Sister passed away peacefully on March 25th, 2021
            at the age of 84.
          </p>

          <p>
            JoAnn retired from AT&amp;T after 31 years of service. She loved
            working, enjoyed several opportunities to travel for work, and
            transferred to offices in both Colorado and New Jersey.
          </p>

          <p>
            Family was important to JoAnn. After retiring, she made it her job
            to make sure our family kept in touch. She loved her personal
            computer and enjoyed putting together digital picture collages and
            memories to share with the family in celebration of someone’s
            birthday.
          </p>

          <p>
            JoAnn made friends easily. She had many great friends, not only at
            home in Utah, but across the country; friends who adored her
            kindness, her feistiness, and her sense of humor. She spent her last
            years at Legacy House of Bountiful where she enjoyed activities like
            bingo, more friends, and especially, the resident dog, Winston.
          </p>

          <p>
            She is survived by her Brothers; Glenn Morrison (Linda), William
            Morrison, James Morrison (Riad), Casey Morrison (Susie), her
            children; Scott Kremer, Michael Kremer, Stacey Klene (Bill), her
            grandchildren; Jodie Smelser (Chris), Brian Kremer (Rachel), Brett
            Kremer, Mikell Kremer, Terris Kremer (Sadie), Kadi Kremer (Jake),
            Sarah Klene (Zel), Great Grandson; Jace Kremer, Sister-in-law;
            Tracie Morrison, Daughter-in-law; Cheri Kremer, and many cousins,
            nieces, and nephews. JoAnn was preceded in death by her Mother;
            Dorothy May Cameron Morrison, her Father; George James Morrison, her
            Sister; Jane Morrison Holbrook, Brother-in-law; Richard Holbrook,
            and her youngest Son; Kenneth Kremer.
          </p>

          <p>There will be no service at this time due to the pandemic.</p>

          <p>
            In lieu of flowers, please consider donating to{" "}
            <a href="https://support.brightfocus.org/default.aspx?tsid=9569">
              Bright Focus Foundation – Macular Degeneration Research
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

function Program() {
  return (
    <div className="Program">
      <div className="Program-Inner">
        <h2>Memorial</h2>
        <div className="embed-container">
          <iframe
            title="JoAnn Kremer Memorial"
            src="https://player.vimeo.com/video/538217547"
            frameBorder="0"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

function Messages() {
  const [name, setName] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [entries, setEntries] = React.useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    if (!name || !message) {
      if (!name && !message) {
        setError("All fields are required.");
      } else {
        if (!message) {
          setError("The Message field is required.");
        }
        if (!name) {
          setError("The Name field is required.");
        }
      }
    } else {
      setLoading(true);
      db.collection("messages")
        .add({
          name,
          message,
          accepted: false,
        })
        .then(() => {
          setSuccess(true);
          setLoading(false);
        })
        .catch((e) => {
          setError(e.message);
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    db.collection("messages")
      .where("accepted", "==", true)
      .get()
      .then((querySnap) => {
        setEntries(querySnap.docs.map((doc) => doc.data()));
        console.log();
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="Messages">
      <h2 className="Messages-Title">Share your favorite memory of JoAnn</h2>
      <div className="Messages-Entries">
        {!!entries.length ? (
          entries.map((entry) => (
            <div className="Messages-Entries-Entry">
              <h3>{entry.name} wrote:</h3>
              {entry.message}
              <hr />
            </div>
          ))
        ) : (
          <p style={{ textAlign: "center", paddingBottom: "20px" }}>
            No entries yet.
          </p>
        )}
      </div>
      <div className="Messages-Form">
        <div className="Messages-Form-Inner">
          <h2 className="serif">Your Memory</h2>
          {success ? (
            <p className="Submit-Success">
              Thank you for sharing your memory. Once it's been accepted it will
              appear here for you and others to enjoy.
            </p>
          ) : (
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="InputGroup">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  disabled={loading}
                />
              </div>
              <div className="InputGroup">
                <label>Message</label>
                <textarea
                  name="message"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  rows="10"
                  disabled={loading}
                />
              </div>
              {error && <p className="form-error">{error}</p>}
              <div className="InputGroup InputGroup-Submit">
                <button type="submit" disabled={loading}>
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

function InLieu() {
  return (
    <div className="InLieu">
      <h2 className="serif">In lieu of flowers</h2>
      <h3 style={{ paddingTop: 20 }}>
        <a href="https://support.brightfocus.org/default.aspx?tsid=9569">
          Bright Focus Foundation – Macular Degeneration Research
        </a>
      </h3>
    </div>
  );
}

export default function Home() {
  const hasAccess = useHasAccess();
  const history = useHistory();
  // Ref for the element that we want to detect whether on screen
  const openRef = React.useRef();
  const onScreenOpen = useOnScreen(openRef, "-100px");
  const [slideshowOpen, setSlideshowOpen] = React.useState(false);
  const [currentSlide, setCurrentSlide] = React.useState(1);

  if (!hasAccess) {
    history.replace("/notfound");
  }

  React.useEffect(() => {
    if (slideshowOpen) {
      document.body.classList.add("slideshow-open");
    } else {
      document.body.classList.remove("slideshow-open");
    }
  }, [slideshowOpen]);

  return (
    <div>
      <Splash />
      {/* PAGES */}
      <div
        style={{
          position: "absolute",
          width: "100%",
          top: "100vh",
          backgroundColor: "#3167B1",
        }}
      >
        <div ref={openRef}>
          <Bio setSlideshowOpen={setSlideshowOpen} />
          <Program />
          <Messages />
        </div>

        <InLieu />
      </div>
      <Flowers open={onScreenOpen} />
      {slideshowOpen && (
        <Slideshow
          setSlideshowOpen={setSlideshowOpen}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
        />
      )}
    </div>
  );
}
