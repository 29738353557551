import React from "react";
import { useAuth } from "../context";
import { Box, Text, Button, LoadingOverlay } from "../components";
import SignInForm from "../forms/SignInForm";

export default function Admin() {
  const { loading, isAuthenticated, signOut } = useAuth();

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <Box
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "12px 32px",
        }}
      >
        <Text as="h1" kind="heading1">
          Admin
        </Text>
        {isAuthenticated && (
          <Button onClick={() => signOut()} title="Sign Out" />
        )}
      </Box>
      <hr />
      {isAuthenticated ? (
        <Box style={{ maxWidth: 1024, padding: 20, margin: "0 auto" }}>
          <Text>Signed In User</Text>
        </Box>
      ) : (
        <Box>
          <Box style={{ paddingTop: 20, width: 400, margin: "0 auto" }}>
            <Text>Sign In</Text>
            <Box style={{ height: 10 }} />
            <SignInForm />
          </Box>
        </Box>
      )}
    </>
  );
}
