import React from "react";

const Box = ({ children, style }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", ...style }}>
      {children}
    </div>
  );
};
export default Box;
