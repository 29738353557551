import React from "react";
import useKeyPress from "../hooks/useKeyPress";

const Slideshow = ({ setSlideshowOpen, currentSlide, setCurrentSlide }) => {
  const lastSlide = 156;
  const images = [];
  const nextPress = useKeyPress("ArrowRight");
  const prevPress = useKeyPress("ArrowLeft");

  React.useEffect(() => {
    const preloadIndex = currentSlide + 1;
    if (!images[preloadIndex]) {
      images[preloadIndex] = new Image();
      images[preloadIndex].src = `../slideshow/JAK_${currentSlide + 1}.jpg`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide]);

  const handlePrevSlide = () => {
    setCurrentSlide((s) => (s === 1 ? lastSlide : s - 1));
  };
  const handleNextSlide = () => {
    setCurrentSlide((s) => (s === lastSlide ? 1 : s + 1));
  };

  React.useEffect(() => {
    if (nextPress) {
      handleNextSlide();
    }
    if (prevPress) {
      handlePrevSlide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextPress, prevPress]);

  return (
    <div className="SlideshowWrapper">
      <button
        className="SlideshowCloseBtn"
        onClick={() => setSlideshowOpen(false)}
      ></button>
      <div className="SlideshowInner">
        <img src={`../slideshow/JAK_${currentSlide}.jpg`} alt="Slideshow" />
        <button
          className="SlideshowPrevBtn"
          onClick={() => handlePrevSlide()}
        ></button>
        <button
          className="SlideshowNextBtn"
          onClick={() => handleNextSlide()}
        ></button>
      </div>
      <p className="SlideshowCount">
        {currentSlide}/{lastSlide}
      </p>
    </div>
  );
};

export default Slideshow;
