// TYPOGRAPHY ====================
export const TYPOGRAPHY = {
  heading1: {
    fontSize: 28,
    lineHeight: 1.5,
  },
  heading2: {
    fontSize: 21,
    lineHeight: 1.5,
  },
  heading2Bold: {
    fontWeight: 700,
    fontSize: 21,
    lineHeight: 1.5,
  },
  base: {
    fontSize: 18,
    lineHeight: 1.5,
  },
  small: {
    fontSize: 14,
    lineHeight: 1.5,
  },
  smallBold: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 1.5,
    letterSpacing: 1,
  },
};

// COLORS ====================

export const COLORS = {
  white: "#FFFFFF",
  gray100: "#3C3A3A",
  gray75: "#606060",
  gray50: "#c4c4c4",
  gray25: "#E5E5E5",
  red100: "#B05457",
  green100: "#8fb57b",
  cobalt: "#3167B1",
};
